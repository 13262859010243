import { Outlet } from '@remix-run/react';

import { LayoutAnchorProvider } from '../components/LayoutAnchors/LayoutAnchors';
import { ProvidersList } from '../components/ProvidersList';
import { RoutedGamePackProviders } from '../components/RoutedGamePack';
import { AdminGamePackWrapper } from '../pages/Admin/GamePack';

export function Component() {
  const providers = [
    <RoutedGamePackProviders />,
    <AdminGamePackWrapper />,
    <LayoutAnchorProvider />,
  ];

  return (
    <ProvidersList providers={providers}>
      <Outlet />
    </ProvidersList>
  );
}
